/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationUserMinimalView } from './organizationUserMinimalView';
import { CounselorDetails } from './counselorDetails';


export interface UserView { 
    uid: string;
    name: string;
    email: string;
    createdAt: string;
    emailVerifiedAt?: string;
    outOfServiceAt?: string;
    roles: Array<string>;
    organizationUsers: Array<OrganizationUserMinimalView>;
    counselorDetails: Array<CounselorDetails>;
    accessToken?: string;
    isOnLeave?: boolean;
}

