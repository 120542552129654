/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IntegrityNoteNew } from './integrityNoteNew';
import { UndesirableReportNoteNew } from './undesirableReportNoteNew';


export interface NewReportNoteBody { 
    reportUid: string;
    undesirable?: UndesirableReportNoteNew;
    integrity?: IntegrityNoteNew;
}

