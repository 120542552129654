import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { BehaviorSubject, catchError, combineLatestWith, lastValueFrom, of, ReplaySubject, } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  locale: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.tryDetermineLanguage() ?? 'en'
  );
  backendLocale = new ReplaySubject<string>(1);

  constructor(
    private auth: AuthService,
    private user: UserService,
    private translate: TranslateService
  ) {
    // NgxTranslate can't detect which languages are available, so we have to tell it here
    this.translate.addLangs(['nl', 'en', 'es', 'pap']);
    this.locale.subscribe((lang) => {
      this.translate.use(lang);
      document.documentElement.lang = lang;
    });
    this.loadProfile();
  }

  async setLanguage(lang: string, backendLocale: string) {
    this.locale.next(lang);
    this.backendLocale.next(backendLocale);
    await lastValueFrom(this.translate.use(lang));
  }

  tryDetermineLanguage(): string | undefined {
    let browserLangs = navigator?.languages;

    if (browserLangs && browserLangs.length > 0) {
      // Try to find a browser language that we support
      return browserLangs
        .map((browserLang) => {
          if (browserLang.indexOf('-') !== -1) {
            browserLang = browserLang.split('-')[0];
          }

          if (browserLang.indexOf('_') !== -1) {
            browserLang = browserLang.split('_')[0];
          }
          return browserLang;
        })
        .find((l) => this.translate.getLangs().includes(l));
    } else {
      return undefined;
    }
  }

  private loadProfile() {
    this.auth.isLoggedIn().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.tryUseLangFromBrowser();
        return;
      }

      this.auth
        .getMe()
        .pipe(
          catchError(() => {
            // Probably not logged in. It not too bad. But we fall back to the browser language then
            this.tryUseLangFromBrowser();
            return of(null);
          }),
          combineLatestWith(this.user.currentOrganization)
        )
        .subscribe(([user, organization]) => {
          if (user && organization) {
            let backendLocale = user.organizationUsers.find(
              (ou) => ou.organizationUid === organization.uid
            )?.localeCode;

            if (!backendLocale) {
              // Fallback 1: See if the user has set a locale for another organization that is also available in the current one:
              backendLocale = user.organizationUsers.find(
                (ou) =>
                  organization.locales.some((l) => l.code === ou.localeCode)
              )?.localeCode;
            }

            if (!backendLocale) {
              // Fallback 2: Try to use the user's locale
              if (user.locale && organization.locales.some((l) => l.code === user.locale)) {
                backendLocale = user.locale;
              }
            }

            let locale = backendLocale ? this.backendLocaleToLocale(backendLocale) : undefined;
            if (!locale || !backendLocale) {
              // Fallback 3: Use the browser language
              locale = this.tryUseLangFromBrowser();
              if (locale) return;
            }

            if ((!locale || !backendLocale) && organization.locales.length > 0) {
              // Fallback 4: Pick any locale that is available in the organization
              backendLocale = organization.locales[0].code;
              locale = this.backendLocaleToLocale(backendLocale);
            }

            if (!locale || !backendLocale) {
              // Fallback 5: Use English
              locale = 'en';
              backendLocale = 'en';
            }

            this.setLanguage(locale, backendLocale).then();
            console.log(
              `Setting language to '${locale}' based on logged in user`
            );
          }
        });
    });
  }

  private backendLocaleToLocale(backendLocale: string): string {
    // The backend uses the (incorrect) language code "pa" for Papiamento. We use "pap" in the app (which is more correct)
    return backendLocale === 'pa' ? 'pap' : backendLocale;
  }

  private localeToBackendLocale(locale: string): string {
    // The backend uses the (incorrect) language code "pa" for Papiamento. We use "pap" in the app (which is more correct)
    return locale === 'pap' ? 'pa' : locale;
  }

  private tryUseLangFromBrowser(): string | undefined {
    const lang = this.tryDetermineLanguage();
    if (lang) {
      this.translate.use(lang);
      this.setLanguage(lang, this.localeToBackendLocale(lang)).then();
    }
    return lang;
  }
}
