<div [ngClass]="{'full-width': fullWidth}" class="info-page-card web">
  <div class="header">
    <div class="length d-flex flex-row align-items-center">
      <ion-icon [name]="randomIcon"/>

      <p class="grayed-out">
        <ion-skeleton-text [animated]="true" style="width: 6ch; height: 16px;"></ion-skeleton-text>
      </p>

      <p class="grayed-out">•</p>
      <p class="grayed-out">
        <ion-skeleton-text [animated]="true" style="width: 7ch; height: 16px"></ion-skeleton-text>
      </p>
    </div>

    <div class="image">
      <ion-skeleton-text [animated]="true" style="width: 100%; height: 150px;"></ion-skeleton-text>
    </div>
  </div>

  <div class="content">
    <div class="title">
      <ion-skeleton-text [animated]="true" style="height: 1em"></ion-skeleton-text>
    </div>
    <p class="preview">
      <ion-skeleton-text [animated]="true" style="height: 1em"></ion-skeleton-text>
    </p>
  </div>
</div>
