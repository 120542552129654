/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserViewMinimal } from './userViewMinimal';


export interface QuestionnaireInstanceUserViewMinimal { 
    questionnaireInstanceUid: string;
    status: QuestionnaireInstanceUserViewMinimal.StatusEnum;
    user: UserViewMinimal;
}
export namespace QuestionnaireInstanceUserViewMinimal {
    export type StatusEnum = 'ASSIGNED' | 'STARTED' | 'COMPLETED';
    export const StatusEnum = {
        Assigned: 'ASSIGNED' as StatusEnum,
        Started: 'STARTED' as StatusEnum,
        Completed: 'COMPLETED' as StatusEnum
    };
}


