/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContentPageCategoryView } from './contentPageCategoryView';


export interface ContentPageCategoryTranslationView { 
    code: string;
    uid: string;
    sortOrder: number;
    name: string;
    description?: string;
    localeCode: string;
    contentPageCategory: ContentPageCategoryView;
}

