/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserViewMinimal } from './userViewMinimal';
import { OrganizationViewMinimal } from './organizationViewMinimal';


export interface ReportView { 
    uid: string;
    reportType: ReportView.ReportTypeEnum;
    status: ReportView.StatusEnum;
    createdAt: string;
    resolvedAt?: string;
    organization: OrganizationViewMinimal;
    userUid?: string;
    counselor: UserViewMinimal;
    canWeContact?: boolean;
    contactName?: string;
    contactFunction?: string;
    contactType?: ReportView.ContactTypeEnum;
    contactEmail?: string;
    contactPhone?: string;
    initialContactOn?: string;
    description?: string;
    isResolved: boolean;
    logbookUids?: Array<string>;
}
export namespace ReportView {
    export type ReportTypeEnum = 'UNDESIRABLE' | 'INTEGRITY';
    export const ReportTypeEnum = {
        Undesirable: 'UNDESIRABLE' as ReportTypeEnum,
        Integrity: 'INTEGRITY' as ReportTypeEnum
    };
    export type StatusEnum = 'DRAFT' | 'SENT' | 'READ' | 'CONTACTED' | 'RESOLVED';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Sent: 'SENT' as StatusEnum,
        Read: 'READ' as StatusEnum,
        Contacted: 'CONTACTED' as StatusEnum,
        Resolved: 'RESOLVED' as StatusEnum
    };
    export type ContactTypeEnum = 'EMAIL' | 'PHONE';
    export const ContactTypeEnum = {
        Email: 'EMAIL' as ContactTypeEnum,
        Phone: 'PHONE' as ContactTypeEnum
    };
}


