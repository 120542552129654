/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportInvolvedPartyView } from './reportInvolvedPartyView';


export interface UndesirableReportNoteView { 
    reporterPerson?: ReportInvolvedPartyView;
    accusedPerson?: ReportInvolvedPartyView;
    counselorName: string;
    hierarchicalPosition?: UndesirableReportNoteView.HierarchicalPositionEnum;
    frequency?: UndesirableReportNoteView.FrequencyEnum;
    period?: UndesirableReportNoteView.PeriodEnum;
    workEnvWhere?: string;
    reporterActions: Array<UndesirableReportNoteView.ReporterActionsEnum>;
    actionReporterComment?: string;
    remarks?: string;
    previousComplaints?: boolean;
    previousComplaintsRemarks?: string;
    reportUid: string;
}
export namespace UndesirableReportNoteView {
    export type HierarchicalPositionEnum = 'ABOVE' | 'EQUAL' | 'BELOW';
    export const HierarchicalPositionEnum = {
        Above: 'ABOVE' as HierarchicalPositionEnum,
        Equal: 'EQUAL' as HierarchicalPositionEnum,
        Below: 'BELOW' as HierarchicalPositionEnum
    };
    export type FrequencyEnum = 'ONE_OFF' | 'OCCASIONALLY' | 'WEEKLY' | 'DAILY';
    export const FrequencyEnum = {
        OneOff: 'ONE_OFF' as FrequencyEnum,
        Occasionally: 'OCCASIONALLY' as FrequencyEnum,
        Weekly: 'WEEKLY' as FrequencyEnum,
        Daily: 'DAILY' as FrequencyEnum
    };
    export type PeriodEnum = 'LESS_THAN_MONTH' | 'ONE_TO_TWO_MONTHS' | 'THREE_TO_SIX_MONTHS' | 'SIX_TO_YEAR' | 'ONE_YEAR_LONGER';
    export const PeriodEnum = {
        LessThanMonth: 'LESS_THAN_MONTH' as PeriodEnum,
        OneToTwoMonths: 'ONE_TO_TWO_MONTHS' as PeriodEnum,
        ThreeToSixMonths: 'THREE_TO_SIX_MONTHS' as PeriodEnum,
        SixToYear: 'SIX_TO_YEAR' as PeriodEnum,
        OneYearLonger: 'ONE_YEAR_LONGER' as PeriodEnum
    };
    export type ReporterActionsEnum = 'MADE_CLEAR' | 'NOTHING_SAID' | 'OTHER_NAMELY' | 'REPORTED_SICK' | 'SHARED_WITH_THIRD_PARTY';
    export const ReporterActionsEnum = {
        MadeClear: 'MADE_CLEAR' as ReporterActionsEnum,
        NothingSaid: 'NOTHING_SAID' as ReporterActionsEnum,
        OtherNamely: 'OTHER_NAMELY' as ReporterActionsEnum,
        ReportedSick: 'REPORTED_SICK' as ReporterActionsEnum,
        SharedWithThirdParty: 'SHARED_WITH_THIRD_PARTY' as ReporterActionsEnum
    };
}


