import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import {
  BehaviorTypeTranslationView,
  ContentPageActivityNew,
  ContentPageActivityView,
  ContentPageCategoryTranslationView,
  ContentPageTranslationView,
  CounselorView,
  GetHotlineTypesResponse,
  GetHotlineTypeTranslationsResponse,
  LogbookView,
  LogbookViewFull,
  LoginPinRequest,
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  MediaView,
  NewLogbook,
  NewQuestionInstanceAnswer,
  NewReport,
  NewSos,
  OrganizationContentPageTranslationView,
  OrganizationUserView,
  OrganizationView,
  PushNotificationForUserView,
  PushNotificationQueueView,
  QuestionInstanceAnswerView,
  QuestionnaireInstanceAnswersView,
  QuestionnaireInstanceUserView,
  QuestionnaireView,
  RegisterDeviceResponse,
  ReportIntegrityView,
  ReportUndesirableView,
  ReportView,
  ResetCredentialsRequest,
  SosView,
  UpdateOrganizationUserMe,
  UpdateUser,
  UserAppSessionNew,
  UserAppSessionView,
  UserMeView,
  UserRegisterRequest,
  UserRegistrationTokenRequest,
  UserRegistrationTokenResponse,
  UserView,
} from '../generated-api';
import { environment } from '../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import { AlertMessage, LocalizedAlertService } from '../service/localized-alert.service';
import { Pageable } from './modelv2/Pageable';
import { LocalReport } from './model/report.model';
import { TokenManagerService } from '../service/token-manager.service';
import { ResponseCache } from './ResponseCache';
import { NO_AUTH } from '../service/auth.interceptor';
import { Feedback } from './modelv2/Feedback';
import { isWeb } from '../util/IsWeb';

export type FullTranslationOverride = Object & Record<string, Record<string, string>>;

@Injectable({
  providedIn: 'root',
})
export class CommunicatorV2Service {
  readonly organizationTranslationOverrideCache: Record<string, ResponseCache<FullTranslationOverride>> = {};

  constructor(private tokenManager: TokenManagerService, private http: HttpClient, private alert: LocalizedAlertService) {}

  getUserMe(): Observable<UserMeView> {
    return this.http.get<UserMeView>(`${environment.apiV2Url}/user/me`).pipe(
      catchError((e, c) =>
        this.handleError(e, c, {
          401: null,
        })
      )
    );
  }

  getCounselors(organizationUid: string): Observable<CounselorView[]> {
    return this.http
      .get<CounselorView[]>(`${environment.apiV2Url}/organization/${organizationUid}/counselors`)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getContentPageTranslation(contentPageUid: String, locale: String, quietError: boolean = false) {
    return this.http
      .get<ContentPageTranslationView>(`${environment.apiV2Url}/content-pages/${contentPageUid}/${locale}`)
      .pipe(catchError((e, c) => this.handleError(e, c, {}, quietError)));
  }

  getOrganizations(): Observable<OrganizationView[]> {
    return this.http.get<OrganizationView[]>(`${environment.apiV2Url}/organizations`).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getContentPageCategories(locale: string): Observable<ContentPageCategoryTranslationView[]> {
    return this.http
      .get<ContentPageCategoryTranslationView[]>(`${environment.apiV2Url}/content-page-categories/${locale}`)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getBehaviorTypes(locale: string): Observable<BehaviorTypeTranslationView[]> {
    return this.http.get<BehaviorTypeTranslationView[]>(`${environment.apiV2Url}/behaviors/translations/${locale}`, {});
  }

  getNewBackendPages(organizationId: string, page?: number, locale?: string): Observable<Pageable<OrganizationContentPageTranslationView>> {
    const params: Record<string, string | number> = {};
    if (page) params['page'] = page;
    if (locale) params['locale'] = locale;

    return this.http
      .get<Pageable<OrganizationContentPageTranslationView>>(`${environment.apiV2Url}/organization/${organizationId}/content-pages`, {
        params,
      })
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getImageById(id: string, width?: number): Observable<Blob> {
    return this.http
      .get(`${environment.apiV2Url}/media/${id}/download?${width ? `width=${width}` : ''}`, { responseType: 'blob' })
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  downloadMedia(id: string): Observable<Blob> {
    return this.getImageById(id);
  }

  getMediaByUid(uid: string): Observable<Blob> {
    return this.http.get(`${environment.apiV2Url}/media/${uid}`, { responseType: 'blob' }).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getUndesirableBehaviorReports(page?: number, size?: number, organizationUid?: string): Observable<Pageable<ReportUndesirableView>> {
    const params: any = {};
    if (page !== undefined) {
      params.page = page.toString();
    }
    if (size !== undefined) {
      params.size = size.toString();
    }
    if (organizationUid !== undefined) {
      params.organizationUid = organizationUid;
    }
    return this.http
      .get<Pageable<ReportUndesirableView>>(`${environment.apiV2Url}/reports/undesirable-behavior/me`, { params })

      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getIntegrityReports(page?: number, size?: number, organizationUid?: string): Observable<Pageable<ReportIntegrityView>> {
    const params: any = {};
    if (page !== undefined) {
      params.page = page.toString();
    }
    if (size !== undefined) {
      params.size = size.toString();
    }
    if (organizationUid !== undefined) {
      params.organizationUid = organizationUid;
    }

    return this.http
      .get<Pageable<ReportIntegrityView>>(`${environment.apiV2Url}/reports/integrity/me`, { params })
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getMediaToken(mediaUid: string): Observable<string> {
    return this.http.get(`${environment.apiV2Url}/media/${mediaUid}/token`, {
      responseType: 'text',
    });
  }

  getReportByUid(uid: string): Observable<LocalReport> {
    return this.http.get<LocalReport>(`${environment.apiV2Url}/reports/${uid}`).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getUndesirableReportByUid(uid: string): Observable<ReportUndesirableView> {
    return this.http
      .get<ReportUndesirableView>(`${environment.apiV2Url}/reports/undesirable-behavior/${uid}`)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getIntegrityReportByUid(uid: string): Observable<ReportIntegrityView> {
    return this.http.get<ReportIntegrityView>(`${environment.apiV2Url}/reports/integrity/${uid}`).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getReportLogBooksByReportUid(uid: string): Observable<LogbookView[]> {
    return this.http.get<LogbookView[]>(`${environment.apiV2Url}/reports/${uid}/logbooks`);
  }

  getLogBooks(page: number = 0, pageSize?: number): Observable<Pageable<LogbookView>> {
    const params: Record<string, string> = { page: page.toString() };
    if (pageSize) params['size'] = pageSize.toString();

    return this.http
      .get<Pageable<LogbookView>>(`${environment.apiV2Url}/logbooks`, {
        params,
      })
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getLogBookById(uid: string): Observable<LogbookViewFull> {
    return this.http.get<LogbookViewFull>(`${environment.apiV2Url}/logbooks/${uid}/full`).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  createLogBook(data: NewLogbook): Observable<LogbookViewFull> {
    return this.http.post<LogbookViewFull>(`${environment.apiV2Url}/logbooks`, data).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  setLogBookMedia(logbookUid: string, mediaUid: string): Observable<MediaView> {
    return this.http
      .post<MediaView>(`${environment.apiV2Url}/logbooks/${logbookUid}/media/${mediaUid}`, {})
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  async uploadMedia(file: FormData): Promise<MediaView> {
    const token = await this.tokenManager.getAccessToken();
    // TODO write this back to a Angular http request
    const response = await fetch(`${environment.apiV2Url}/media`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'multipart/form-data'
      },
      body: file,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  }

  getOrganizationCounselorByUserIdAndOrganizationId(userUid: string, organizationUid: string): Observable<CounselorView> {
    return this.http
      .get<CounselorView>(`${environment.apiV2Url}/organization/${organizationUid}/counselors/${userUid}`)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getHotlineTypes(): Observable<GetHotlineTypesResponse> {
    return this.http.get<GetHotlineTypesResponse>(`${environment.apiV2Url}/hotline-types`).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getHotlineTypesByLocaleUid(locale: string): Observable<GetHotlineTypeTranslationsResponse> {
    return this.http
      .get<GetHotlineTypeTranslationsResponse>(`${environment.apiV2Url}/hotline-types/${locale}`)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  sendReport(report: NewReport): Observable<ReportView> {
    return this.http.post<ReportView>(`${environment.apiV2Url}/reports`, report).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  startAppSession(request: UserAppSessionNew): Observable<UserAppSessionView> {
    return this.http
      .post<UserAppSessionView>(`${environment.apiV2Url}/analytics/app-session`, request)
      .pipe(catchError((e, c) => this.handleError(e, c, {}, true)));
  }

  heartbeatAppSession(appSessionUid: string): Observable<UserAppSessionView> {
    return this.http
      .post<UserAppSessionView>(`${environment.apiV2Url}/analytics/app-session/${appSessionUid}/heartbeat`, {})
      .pipe(catchError((e, c) => this.handleError(e, c, {}, true)));
  }

  endAppSession(appSessionUid: string): Observable<UserAppSessionView> {
    return this.http
      .post<UserAppSessionView>(`${environment.apiV2Url}/analytics/app-session/${appSessionUid}/end`, {})
      .pipe(catchError((e, c) => this.handleError(e, c, {}, true)));
  }

  createContentPageActivity(request: ContentPageActivityNew): Observable<ContentPageActivityView> {
    return this.http
      .post<ContentPageActivityView>(`${environment.apiV2Url}/analytics/content-page-activity`, request)
      .pipe(catchError((e, c) => this.handleError(e, c, {}, true)));
  }

  closeContentPageActivity(contentPageActivityUid: string): Observable<ContentPageActivityView> {
    return this.http
      .post<ContentPageActivityView>(`${environment.apiV2Url}/analytics/content-page-activity/${contentPageActivityUid}/close`, {})
      .pipe(catchError((e, c) => this.handleError(e, c, {}, true)));
  }

  getQuestionnaireInstancesById(id: string): Observable<QuestionnaireInstanceUserView> {
    return this.http
      .get<QuestionnaireInstanceUserView>(`${environment.apiV2Url}/questionnaires/${id}/instances`)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getQuestionnaireById(id: string): Observable<QuestionnaireView> {
    return this.http.get<QuestionnaireView>(`${environment.apiV2Url}/questionnaires/${id}`).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getQuestionnaires(organizationUid: string): Observable<Pageable<QuestionnaireInstanceUserView>> {
    return this.http
      .get<Pageable<QuestionnaireInstanceUserView>>(`${environment.apiV2Url}/questionnaires/instances/me?organizationUid=${organizationUid}`)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  sendQuestionnaireInstanceAnswers(questionnaireUid: string, instanceUid: string, answers: NewQuestionInstanceAnswer): Observable<QuestionInstanceAnswerView> {
    return this.http
      .post<QuestionInstanceAnswerView>(`${environment.apiV2Url}/questionnaires/${questionnaireUid}/instances/${instanceUid}/me/answers`, answers)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  updateQuestionnaireInstanceAnswers(questionnaireUid: string, instanceUid: string, answers: any, questionUid: string): Observable<QuestionInstanceAnswerView> {
    return this.http
      .put<QuestionInstanceAnswerView>(`${environment.apiV2Url}/questionnaires/${questionnaireUid}/instances/${instanceUid}/me/answers/${questionUid}`, answers)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getQuestionnaireInstanceAnswers(questionnaireUid: string, instanceUid: string): Observable<QuestionnaireInstanceAnswersView> {
    return this.http
      .get<QuestionnaireInstanceAnswersView>(`${environment.apiV2Url}/questionnaires/${questionnaireUid}/instances/${instanceUid}/me/answers`)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getUserNotifications(): Observable<Pageable<PushNotificationForUserView>> {
    return this.http
      .get<Pageable<PushNotificationForUserView>>(`${environment.apiV2Url}/push-notifications/me`)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getUserNotificationByUid(notificationUid: string): Observable<PushNotificationQueueView> {
    return this.http.get<PushNotificationQueueView>(`${environment.apiV2Url}/push-notifications/me/${notificationUid}`);
  }

  pushNotificationRegister(token: string, deviceType: string): Observable<void> {
    console.log('registering token', token, deviceType);
    return this.http.post<void>(`${environment.apiV2Url}/push-notifications/register-token`, {
      token: token,
      deviceType: deviceType,
    });
  }

  pushNotificationRegisterEmailSubscription(): Observable<void> {
    return this.http.post<void>(`${environment.apiV2Url}/push-notifications/register-email-subscription`, {});
  }

  pushNotificationReceived(notificationUid: string): Observable<void> {
    return this.http.post<void>(`${environment.apiV2Url}/unauthenticated/push-notification/${notificationUid}/received`, {});
  }

  pushNotificationClicked(notificationQueueUid: string): Observable<PushNotificationQueueView> {
    return this.http.put<PushNotificationQueueView>(`${environment.apiV2Url}/push-notifications/clicked/${notificationQueueUid}`, {});
  }

  getUserByUid(uid: string): Observable<UserView> {
    return this.http.get<UserView>(`${environment.apiV2Url}/user/${uid}`).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  updateUser(userUid: string, userData: UpdateUser): Observable<UserMeView> {
    return this.http.put<UserMeView>(`${environment.apiV2Url}/users/${userUid}`, userData).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  updateUserMe(data: UpdateUser): Observable<UserMeView> {
    return this.http.put<UserMeView>(`${environment.apiV2Url}/user/me`, data).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  setUserLocale(locale: string): Observable<void> {
    return this.http.post<void>(`${environment.apiV2Url}/user/me/locale`, { locale }).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  updateOrganizationUserMe(organizationUid: string, data: UpdateOrganizationUserMe): Observable<OrganizationUserView> {
    return this.http.put<any>(`${environment.apiV2Url}/organization-users/${organizationUid}/me`, data).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getOrganizationUser(organizationUid: string, userUid: string): Observable<OrganizationUserView> {
    return this.http
      .get<OrganizationUserView>(`${environment.apiV2Url}/organization-users/${organizationUid}/${userUid}`)
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  login(email: string, password: string): Observable<Required<LoginResponse>> {
    const request: LoginRequest = {
      email,
      password,
      scope: environment.appLoginScope,
    };
    return this.http
      .post<Required<LoginResponse>>(`${environment.apiV2Url}/unauthenticated/login/${isWeb() ? 'web-app' : 'app'}`, request, {
        withCredentials: true,
        context: new HttpContext().set(NO_AUTH, true),
      })
      .pipe(catchError((e, c) => this.handleError(e, c, { 400: 'InvalidCredentials' })));
  }

  loginWithPinCode(deviceId: string, pin: string): Observable<Required<LoginResponse>> {
    const request: LoginPinRequest = {
      deviceId,
      pin,
      scope: environment.appLoginScope,
    };
    return this.http
      .post<Required<LoginResponse>>(`${environment.apiV2Url}/unauthenticated/login/pin/${isWeb() ? 'web-app' : 'app'}`, request, {
        withCredentials: true,
        context: new HttpContext().set(NO_AUTH, true),
      })
      .pipe(catchError((e, c) => this.handleError(e, c, { 400: 'InvalidPinCode' })));
  }

  verifyRegistrationToken(token: string): Observable<UserRegistrationTokenResponse> {
    const request: UserRegistrationTokenRequest = {
      token: token,
      scope: environment.appLoginScope,
    };
    return this.http
      .post<UserRegistrationTokenResponse>(`${environment.apiV2Url}/unauthenticated/verify-registration-token`, request)
      .pipe(catchError((e, c) => this.handleError(e, c, { 400: 'InvalidAccessCode' })));
  }

  logout(logoutRequest: LogoutRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiV2Url}/auth/logout`, logoutRequest, {
      withCredentials: true,
    });
  }

  setPin(pincode: string, oldPin: string | undefined): Observable<void> {
    return this.http.post<void>(`${environment.apiV2Url}/auth/set-pin`, {
      pin: pincode,
      oldPin: oldPin,
    });
  }

  registerDevice(): Observable<RegisterDeviceResponse> {
    return this.http.post<RegisterDeviceResponse>(`${environment.apiV2Url}/auth/register-device`, {}).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  activationRegister(userRegisterData: UserRegisterRequest): Observable<UserView> {
    return this.http.post<UserView>(`${environment.apiV2Url}/activation/register`, userRegisterData).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  resetCredentials(credentials: ResetCredentialsRequest): Observable<void> {
    const pswReset = {
      token: credentials.token,
      password: credentials.password,
    };
    const pinReset = {
      token: credentials.token,
      pin: credentials.pin,
    };
    if (credentials.pin) {
      return this.http.post<void>(`${environment.apiV2Url}/unauthenticated/reset-credentials`, pinReset).pipe(catchError((e, c) => this.handleError(e, c, {})));
    } else if (credentials.password) {
      return this.http.post<void>(`${environment.apiV2Url}/unauthenticated/reset-credentials`, pswReset).pipe(catchError((e, c) => this.handleError(e, c, {})));
    } else {
      return throwError(() => new Error('Missing pin or password'));
    }
  }

  forgotCredentials(email: string, type: string): Observable<void> {
    return this.http
      .post<void>(`${environment.apiV2Url}/unauthenticated/forgot-credentials`, {
        email: email,
        type: type,
        target: isWeb() ? 'web-app' : 'app',
      })
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  updatePassword(password: string, newPassword: string): Observable<void> {
    return this.http
      .post<void>(`${environment.apiV2Url}/auth/update-password`, {
        oldPassword: password,
        newPassword: newPassword,
      })
      .pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  getSosList(organizationId: string): Observable<Pageable<SosView>> {
    return this.http.get<Pageable<SosView>>(`${environment.apiV2Url}/sos/organization/${organizationId}`);
  }

  getSosByUid(uid: string): Observable<SosView> {
    return this.http.get<SosView>(`${environment.apiV2Url}/sos/${uid}`).pipe(catchError((e, c) => this.handleError(e, c, {})));
  }

  sendSOS(data: NewSos): Observable<any> {
    return this.http.post(`${environment.apiV2Url}/sos/send-sos`, data).pipe(catchError((e, c) => this.handleError(e, c, { 400: 'Default' })));
  }

  getOrganizationTranslationOverrides(organizationUid: string) {
    if (!this.organizationTranslationOverrideCache[organizationUid]) {
      this.organizationTranslationOverrideCache[organizationUid] = new ResponseCache<FullTranslationOverride>(
        () =>
          this.http
            .get<FullTranslationOverride>(`${environment.apiV2Url}/translation-override/${organizationUid}`)
            .pipe(catchError((e, c) => this.handleError(e, c, {}))),
        60 * 60 * 1000
      );
    }

    return this.organizationTranslationOverrideCache[organizationUid].getData();
  }

  sendFeedback({ name, email, organization, description, subject, attachments }: Feedback) {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('email', email);
    if (organization) formData.append('organization', organization);
    formData.append('description', description);
    formData.append('subject', subject);
    attachments.forEach((attachment) => {
      formData.append('files', attachment.file, attachment.name);
    });

    return this.http.post(`${environment.apiV2Url}/unauthenticated/feedback/app`, formData, {
      withCredentials: false,
      context: new HttpContext().set(NO_AUTH, true),
    });
  }

  sendFeedbackWeb(feedbackForm: Feedback) {
    const formData = new FormData();

    formData.append('name', feedbackForm.name);
    formData.append('email', feedbackForm.email);
    if (feedbackForm.organization) formData.append('organization', feedbackForm.organization);
    formData.append('description', feedbackForm.description);
    formData.append('subject', feedbackForm.subject);
    feedbackForm.attachments.forEach((attachment) => {
      formData.append('files', attachment.file, attachment.name);
    });
    if (!feedbackForm.hCaptchaResponse) {
      return throwError(() => new Error('hCaptchaResponse is required on web'));
    }
    formData.append('hCaptchaResponse', feedbackForm.hCaptchaResponse);
    formData.append('source', 'Web App');

    return this.http.post(`${environment.apiV2Url}/unauthenticated/feedback`, formData, {
      withCredentials: false,
      context: new HttpContext().set(NO_AUTH, true),
    });
  }

  /**
   *
   * @param err The error that occurred
   * @param _c The observable that was being called when the error occurred
   * @param statusAlerts Maps status codes (e.g. 400, 404, etc) to alert messages. If the value for a status code is null, it means no alert should be shown (similar to quiet mode, but for specific error codes).
   * @param quiet If true, no alert will be shown
   * @private
   */
  private handleError<T>(
    err: HttpErrorResponse,
    _c: Observable<T>,
    statusAlerts: {
      [key: number]: AlertMessage | null;
    },
    quiet = false
  ): Observable<T> {
    console.error('Request failed: ' + err.message, err);

    if (quiet || statusAlerts[err.status] == null) {
      return throwError(() => err);
    }
    if (statusAlerts[err.status]) {
      this.alert.showAlert(statusAlerts[err.status] ?? 'Default');
    } else if (err.status === 422) {
      this.alert.showAlert('InvalidInput');
    } else {
      this.alert.showAlert();
    }

    return throwError(() => err);
  }
}
