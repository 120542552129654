import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthService } from './service/auth.service';
import { TermsPage } from './terms/terms.page';
import { FeedbackComponent } from './feedback/feedback.component';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './service/auth.interceptor';
import { CoreModule } from './core/core.module';
import { IonicStorageModule, StorageConfig } from '@ionic/storage-angular';
import { IonicStorageService } from './service/ionic-storage.service';
import { Capacitor } from '@capacitor/core';
import { Drivers } from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { initializeApp } from 'firebase/app';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { map, Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { merge } from 'lodash-es';

export function createTranslateLoader() {
  return new ReportAppTranslationLoader();
}

export class ReportAppTranslationLoader implements TranslateLoader {
  constructor() {}

  getTranslation(lang: string): Observable<object> {
    // We use an import here, so that Webpack can create a separate chunk for each language. Since webpack will hash these filenames, we can
    // rely on the browser's cache to store the translations.
    if (environment.i18nOverrides && environment.i18nOverrides[lang]) {
      console.log(`Loading translations for ${lang} with override ${environment.i18nOverrides[lang]}`);
      return fromPromise(
        Promise.all([
          import(`../assets/i18n/${lang}.json`),
          import(`../assets/i18n/${environment.i18nOverrides[lang]}.json`),
        ])).pipe(map(([lang, override]) => merge(lang, override)));
    }
    return fromPromise(import(`../assets/i18n/${lang}.json`))
  }
}


function storageConfig(): StorageConfig {
  if (!Capacitor.isNativePlatform()) {
    return { driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage] };
  }

  return {
    driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
  };
}

initializeApp(environment.firebase);

@NgModule({
  declarations: [AppComponent, TermsPage, FeedbackComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    IonicStorageModule.forRoot(storageConfig()),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CoreModule,
    FormsModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthService,
    IonicStorageService,
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
})
export class AppModule {
}
