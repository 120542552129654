import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-card',
  templateUrl: './loading-card.component.html',
  styleUrls: ['./loading-card.component.scss'],
})
export class LoadingCardComponent implements OnInit {

  @Input() fullWidth = false;
  randomIcon: string

  constructor() {
    const icons = ['book-outline', 'headset-outline', 'videocam-outline']
    this.randomIcon = icons[Math.floor(Math.random() * icons.length)]
  }

  ngOnInit() {

  }

}
