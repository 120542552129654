/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserAppSessionView { 
    uid: string;
    userUid?: string;
    organizationUid?: string;
    timeStarted: string;
    timeEnded?: string;
    heartbeatSeconds: number;
    lastHeartbeat?: string;
    appVersion?: string;
    platform?: string;
}

