/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewPushNotification { 
    subject: NewPushNotification.SubjectEnum;
    title: string;
    body: string;
    resourceUrl: string;
    scheduledAt?: string;
}
export namespace NewPushNotification {
    export type SubjectEnum = 'QUESTIONNAIRE' | 'CONTENT_PAGE' | 'SOS' | 'CUSTOM';
    export const SubjectEnum = {
        Questionnaire: 'QUESTIONNAIRE' as SubjectEnum,
        ContentPage: 'CONTENT_PAGE' as SubjectEnum,
        Sos: 'SOS' as SubjectEnum,
        Custom: 'CUSTOM' as SubjectEnum
    };
}


