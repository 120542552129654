import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SliceStringPipe } from './pipes/slice-string.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { IonicModule } from '@ionic/angular';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SosComponent } from '../info/shared/sos/sos.component';
import { TopMessageComponent } from './components/top-message/top-message.component';
import { FormsModule } from '@angular/forms';
import { CACHE_PATH, ImageCacheService, MAX_CACHE_AGE_MS } from './services/image-cache.service';
import { ImageCacheDirective } from './directives/image-cache.directive';
import { MediaImageComponent } from './components/media-image/media-image.component';
import { MediaAudioComponent } from './components/media-audio/media-audio.component';
import { TranslateModule } from '@ngx-translate/core';
import { UpdateAvailableComponent } from './components/update-available/update-available.component';
import { HyphenatePipe } from './pipes/hyphenate.pipe';


@NgModule({
  declarations: [
    SafeHtmlPipe,
    SliceStringPipe,
    DurationPipe,
    AudioPlayerComponent,
    SafeUrlPipe,
    SosComponent,
    MediaImageComponent,
    TopMessageComponent,
    UpdateAvailableComponent,
    ImageCacheDirective,
    MediaAudioComponent,
    HyphenatePipe,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule
  ],
  providers: [
    { provide: CACHE_PATH, useValue: 'CACHE_IMAGES' },
    { provide: MAX_CACHE_AGE_MS, useValue: 1000 * 60 * 60 * 24 * 7 },
    ImageCacheService
  ],
  exports: [
    SafeHtmlPipe,
    SliceStringPipe,
    DurationPipe,
    AudioPlayerComponent,
    SafeUrlPipe,
    SosComponent,
    MediaImageComponent,
    TopMessageComponent,
    ImageCacheDirective,
    MediaAudioComponent,
    ImageCacheDirective,
    UpdateAvailableComponent,
    HyphenatePipe
  ]
})
export class CoreModule {
}
