/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewReportUndesirable { 
    behaviorType: string;
    behaviorOrigin: NewReportUndesirable.BehaviorOriginEnum;
    behaviorDirectedAt: NewReportUndesirable.BehaviorDirectedAtEnum;
}
export namespace NewReportUndesirable {
    export type BehaviorOriginEnum = 'INTERNAL' | 'EXTERNAL';
    export const BehaviorOriginEnum = {
        Internal: 'INTERNAL' as BehaviorOriginEnum,
        External: 'EXTERNAL' as BehaviorOriginEnum
    };
    export type BehaviorDirectedAtEnum = 'ME' | 'BYSTANDER';
    export const BehaviorDirectedAtEnum = {
        Me: 'ME' as BehaviorDirectedAtEnum,
        Bystander: 'BYSTANDER' as BehaviorDirectedAtEnum
    };
}


