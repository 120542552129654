/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionnaireView } from './questionnaireView';
import { OrganizationView } from './organizationView';


export interface OrganizationQuestionnaireView { 
    questionnaire: QuestionnaireView;
    organization: OrganizationView;
}

