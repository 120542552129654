/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserView } from './userView';
import { OrganizationView } from './organizationView';
import { MediaView } from './mediaView';


export interface LogbookViewFull { 
    id: string;
    uid: string;
    user: UserView;
    organization: OrganizationView;
    description?: string;
    media: Array<MediaView>;
    title: string;
    createdAt: string;
    updatedAt: string;
}

