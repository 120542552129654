/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NewUndesirableReportResolution } from './newUndesirableReportResolution';
import { NewIntegrityReportResolution } from './newIntegrityReportResolution';


export interface ReportResolutionBody { 
    undesirable?: NewUndesirableReportResolution;
    integrity?: NewIntegrityReportResolution;
}

