/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LoginResponse { 
    accessToken: string;
    refreshToken?: string;
    requiredAction?: LoginResponse.RequiredActionEnum;
}
export namespace LoginResponse {
    export type RequiredActionEnum = 'CONFIGURE_PIN' | 'CONFIGURE_TOTP' | 'CONFIRM_TOTP';
    export const RequiredActionEnum = {
        ConfigurePin: 'CONFIGURE_PIN' as RequiredActionEnum,
        ConfigureTotp: 'CONFIGURE_TOTP' as RequiredActionEnum,
        ConfirmTotp: 'CONFIRM_TOTP' as RequiredActionEnum
    };
}


