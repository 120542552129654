/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionOptionView } from './questionOptionView';
import { QuestionTranslationView } from './questionTranslationView';
import { MediaView } from './mediaView';


export interface QuestionView { 
    uid: string;
    questionnaireUid: string;
    organizationUid: string;
    sortOrder: number;
    type: QuestionView.TypeEnum;
    translations: Array<QuestionTranslationView>;
    options: Array<QuestionOptionView>;
    media?: Array<MediaView>;
    embedUrl?: string;
}
export namespace QuestionView {
    export type TypeEnum = 'MULTIPLE_CHOICE' | 'SINGLE_CHOICE' | 'FIVE_STAR_RATING' | 'OPEN' | 'TEN_STAR_RATING';
    export const TypeEnum = {
        MultipleChoice: 'MULTIPLE_CHOICE' as TypeEnum,
        SingleChoice: 'SINGLE_CHOICE' as TypeEnum,
        FiveStarRating: 'FIVE_STAR_RATING' as TypeEnum,
        Open: 'OPEN' as TypeEnum,
        TenStarRating: 'TEN_STAR_RATING' as TypeEnum
    };
}


