<a [routerLink]="routerLink" class="flex items-center gap-4 py-2 transition-all text-decoration-none text-dark" routerLinkActive="text-primary">
  <div class="w-5 d-grid items-center">
    <ion-icon *ngIf="!iconSrc && icon" [name]="icon" size="large"></ion-icon>
    <ion-icon *ngIf="iconSrc" [src]="iconSrc" size="large"></ion-icon>
  </div>
  <div>
    <ng-content/>
  </div>
</a>

