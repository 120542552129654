/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NavigationMenuItem { 
    icon: string;
    uriKey: string;
    label: string;
    translationKey: string;
}

