/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UpdateOrganizationSlider } from './updateOrganizationSlider';
import { UpdateOrganizationCustomPageType } from './updateOrganizationCustomPageType';
import { CustomContentPageCategoryView } from './customContentPageCategoryView';


export interface UpdateOrganization { 
    name?: string;
    themeColor?: string;
    logoUid?: string;
    emblemUid?: string;
    parentOrganizationUid?: string;
    organizationFeatures?: Array<string>;
    organizationLocales?: Array<string>;
    defaultCounselor?: string;
    slider?: UpdateOrganizationSlider;
    customPageType?: UpdateOrganizationCustomPageType;
    customContentPageCategories?: Array<CustomContentPageCategoryView>;
    isDemo?: boolean;
}

