/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NewQuestionnaireTranslation } from './newQuestionnaireTranslation';


export interface UpdateQuestionnaire { 
    type?: UpdateQuestionnaire.TypeEnum;
    feedIconUid?: string;
    headerImageUid?: string;
    translations?: Array<NewQuestionnaireTranslation>;
    media?: Array<string>;
    embedUrl?: string;
    shared?: boolean;
}
export namespace UpdateQuestionnaire {
    export type TypeEnum = 'ELEARNING' | 'SURVEY';
    export const TypeEnum = {
        Elearning: 'ELEARNING' as TypeEnum,
        Survey: 'SURVEY' as TypeEnum
    };
}


