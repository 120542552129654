/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationView } from './organizationView';
import { ContentPageView } from './contentPageView';


export interface OrganizationContentPageView { 
    organization: OrganizationView;
    contentPage: ContentPageView;
    sortOrder: number;
    pinned: boolean;
    draft: boolean;
    customContentPageCategory?: string;
    createdAt: string;
    updatedAt: string;
    publishAt?: string;
}

