// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {EnvironmentConfig} from './EnvironmentConfig';

export const environment: EnvironmentConfig = {
  production: false,
  apiV2Url: 'https://report-app.dev.gametailors.com/api-v2',
  maxUploadSize: 20 * 1024 * 1024,
  minPasswordLength: 12,
  disableImageCache: false,
  analyticsSessionHeartbeatSeconds: 60,
  firebase: {
    apiKey: 'AIzaSyB-p2TcMvQpb3ioxY6_yWnFXPE6PWvFnmw',
    GCM_SENDER_ID: '945877952094',
    projectId: 'report-app-dev-ae134',
    storageBucket: 'report-app-dev-ae134.appspot.com',
    appId: '1:945877952094:ios:f27aec99ee9aa8055eb6cf',
    messagingSenderId: '945877952094',
    APNAuthKeyId: 'RR67B6X7N9'
  },
  appLoginScope: 'MAIN',
  hCaptchaSiteKey: 'f12854ee-c6a8-44c2-9f02-bc220bb825ee',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
