/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContentPageTranslationBlockUpdate } from './contentPageTranslationBlockUpdate';


export interface ContentPageTranslationUpdate { 
    locale?: string;
    title?: string;
    preview?: string;
    blocks?: Array<ContentPageTranslationBlockUpdate>;
}

