/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContentPageActivityStatistics } from './contentPageActivityStatistics';
import { ContentPageCategoryStatistics } from './contentPageCategoryStatistics';
import { ContentPageCustomCategoryStatistics } from './contentPageCustomCategoryStatistics';


export interface ContentPagesActivityStatistics { 
    pages: Array<ContentPageActivityStatistics>;
    categories: Array<ContentPageCategoryStatistics>;
    customCategories: Array<ContentPageCustomCategoryStatistics>;
    totalViews: number;
    averageViewSeconds: number;
}

