/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomPushNotificationView } from './customPushNotificationView';
import { OrganizationContentPageTranslationView } from './organizationContentPageTranslationView';


export interface PageableResponseCustomPushNotificationView { 
    pages: Array<CustomPushNotificationView>;
    totalPages: number;
    itemCount: number;
    currentPage: number;
    contentPageTranslations?: Array<OrganizationContentPageTranslationView>;
}

