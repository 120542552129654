import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-desktop-menu-item',
  templateUrl: './desktop-menu-item.component.html',
  styles: ''
})
export class DesktopMenuItemComponent {

  @Input() routerLink?: string;
  @Input() icon?: string;
  @Input() iconSrc?: string;

  constructor() { }

}
