/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LogbookStatistics { 
    /**
     * Total number of logbooks created in the history of the app
     */
    totalLogbooksAllTime: number;
    /**
     * Total number of logbooks created in the requested period
     */
    totalLogbooks: number;
}

