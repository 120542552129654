/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportResponseTimes } from './reportResponseTimes';


export interface UndesirableReportsPersonalStatistics { 
    /**
     * Number of undesirable reports created in the requested period
     */
    totalReports: number;
    /**
     * Total number of undesirable reports created in the history of the app
     */
    totalReportsAllTime: number;
    responseTime: ReportResponseTimes;
    reportsPerBehaviorType: { [key: string]: number; };
    reportsPerOrigin: { [key: string]: number; };
    reportsPerDirectedAt: { [key: string]: number; };
    reportsPerResolution: { [key: string]: number; };
    reportsPerStepTaken: { [key: string]: number; };
    reportsPerReporterMoved: { [key: string]: number; };
    reportsPerAccusedMoved: { [key: string]: number; };
}

