/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionnaireMinimalView } from './questionnaireMinimalView';
import { UserViewMinimal } from './userViewMinimal';
import { QuestionInstanceAnswerView } from './questionInstanceAnswerView';


export interface QuestionnaireInstanceUserView { 
    questionnaireInstanceUid: string;
    start: string;
    end: string;
    questionnaireMinimalView: QuestionnaireMinimalView;
    status: QuestionnaireInstanceUserView.StatusEnum;
    answers: Array<QuestionInstanceAnswerView>;
    user: UserViewMinimal;
}
export namespace QuestionnaireInstanceUserView {
    export type StatusEnum = 'ASSIGNED' | 'STARTED' | 'COMPLETED';
    export const StatusEnum = {
        Assigned: 'ASSIGNED' as StatusEnum,
        Started: 'STARTED' as StatusEnum,
        Completed: 'COMPLETED' as StatusEnum
    };
}


