/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContentPageCategoryView } from './contentPageCategoryView';


export interface ContentPageCategoryStatistics { 
    category: ContentPageCategoryView;
    totalViews: number;
    averageViewSeconds: number;
}

