/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserRegistrationTokenResponse { 
    valid: boolean;
    validUntil?: string;
    target?: UserRegistrationTokenResponse.TargetEnum;
    activationToken?: string;
}
export namespace UserRegistrationTokenResponse {
    export type TargetEnum = 'CMS' | 'APP';
    export const TargetEnum = {
        Cms: 'CMS' as TargetEnum,
        App: 'APP' as TargetEnum
    };
}


