import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '../../service/locale.service';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'hyphenate'
})
export class HyphenatePipe implements PipeTransform {

  readonly hyphenations: Record<string, Record<string, string>> = {
    'en': {},
    'nl': {
      'vertrouwenspersoon': 'vertrouwens\u00ADpersoon',
      'vertrouwenspersonen': 'vertrouwens\u00ADpersonen',
    }
  }

  constructor(private localeService: LocaleService) {
    if (!environment.production) {
      this.validateHyphenations()
    }
  }

  validateHyphenations() {
    for (const locale in this.hyphenations) {
      for (const key in this.hyphenations[locale]) {
        if (!this.hyphenations[locale][key].includes('\u00AD')) {
          console.error(`Hyphenation for ${key} in ${locale} is missing a soft hyphen`)
        }
        if (key != this.hyphenations[locale][key].replace('\u00AD', '')) {
          console.error(`Hyphenation for ${key} in ${locale} does not match the original`)
        }
      }
    }
  }

  transform(value: string | undefined): string | undefined {
    if (!value) return value;

    const hyph = this.hyphenations[this.localeService.locale.value]
    for (const key in hyph) {
      value = value.toString().replace(new RegExp(key, 'g'), hyph[key])
    }
    return value
  }

}
