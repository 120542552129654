/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionnaireInstanceView } from './questionnaireInstanceView';
import { OrganizationContentPageTranslationView } from './organizationContentPageTranslationView';


export interface PageableResponseQuestionnaireInstanceView { 
    pages: Array<QuestionnaireInstanceView>;
    totalPages: number;
    itemCount: number;
    currentPage: number;
    contentPageTranslations?: Array<OrganizationContentPageTranslationView>;
}

