/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuditLogView { 
    id: number;
    createdAt: string;
    doneByUserUid: string;
    organizationUid: string;
    content: string;
    operation: AuditLogView.OperationEnum;
}
export namespace AuditLogView {
    export type OperationEnum = 'CREATE' | 'UPDATE' | 'DELETE';
    export const OperationEnum = {
        Create: 'CREATE' as OperationEnum,
        Update: 'UPDATE' as OperationEnum,
        Delete: 'DELETE' as OperationEnum
    };
}


