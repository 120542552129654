/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PushNotificationView } from './pushNotificationView';


export interface PushNotificationForUserView { 
    pushNotification: PushNotificationView;
    sentAt: string;
    firstRetrievedAt?: string;
    firstClickedAt?: string;
}

