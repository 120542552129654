import { Component } from '@angular/core';

@Component({
  selector: 'app-desktop-menu-logo',
  templateUrl: './desktop-menu-logo.component.html',
  styleUrls: ['./desktop-menu-logo.component.scss'],
})
export class DesktopMenuLogoComponent {

  constructor() { }

}
