/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaView } from './mediaView';


export interface ContentPageTranslationBlockView { 
    uid: string;
    sortOrder: number;
    type: ContentPageTranslationBlockView.TypeEnum;
    body?: string;
    media?: MediaView;
    embedUrl?: string;
}
export namespace ContentPageTranslationBlockView {
    export type TypeEnum = 'TEXT' | 'IMAGE' | 'AUDIO' | 'FILE' | 'EMBED';
    export const TypeEnum = {
        Text: 'TEXT' as TypeEnum,
        Image: 'IMAGE' as TypeEnum,
        Audio: 'AUDIO' as TypeEnum,
        File: 'FILE' as TypeEnum,
        Embed: 'EMBED' as TypeEnum
    };
}


