/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NewQuestionTranslation } from './newQuestionTranslation';
import { NewQuestionOption } from './newQuestionOption';


export interface NewQuestion { 
    sortOrder: number;
    type: NewQuestion.TypeEnum;
    translations: Array<NewQuestionTranslation>;
    options: Array<NewQuestionOption>;
    media?: Array<string>;
}
export namespace NewQuestion {
    export type TypeEnum = 'MULTIPLE_CHOICE' | 'SINGLE_CHOICE' | 'FIVE_STAR_RATING' | 'OPEN' | 'TEN_STAR_RATING';
    export const TypeEnum = {
        MultipleChoice: 'MULTIPLE_CHOICE' as TypeEnum,
        SingleChoice: 'SINGLE_CHOICE' as TypeEnum,
        FiveStarRating: 'FIVE_STAR_RATING' as TypeEnum,
        Open: 'OPEN' as TypeEnum,
        TenStarRating: 'TEN_STAR_RATING' as TypeEnum
    };
}


