/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationView } from './organizationView';
import { MediaView } from './mediaView';


export interface ContentPageView { 
    uid: string;
    label: string;
    category: string;
    ownerOrganization: OrganizationView;
    shared: boolean;
    availableLanguages: Array<string>;
    feedIcon?: MediaView;
    createdAt: string;
    updatedAt: string;
    pageType: ContentPageView.PageTypeEnum;
    basedOn?: string;
    contentMediumType: ContentPageView.ContentMediumTypeEnum;
    madeByReportApp: boolean;
    isDefault: boolean;
}
export namespace ContentPageView {
    export type PageTypeEnum = 'KNOWLEDGE' | 'ORGANIZATION' | 'FEED_ONLY' | 'ALL';
    export const PageTypeEnum = {
        Knowledge: 'KNOWLEDGE' as PageTypeEnum,
        Organization: 'ORGANIZATION' as PageTypeEnum,
        FeedOnly: 'FEED_ONLY' as PageTypeEnum,
        All: 'ALL' as PageTypeEnum
    };
    export type ContentMediumTypeEnum = 'ARTICLE' | 'PODCAST' | 'VIDEO';
    export const ContentMediumTypeEnum = {
        Article: 'ARTICLE' as ContentMediumTypeEnum,
        Podcast: 'PODCAST' as ContentMediumTypeEnum,
        Video: 'VIDEO' as ContentMediumTypeEnum
    };
}


