/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationUserMinimalView } from './organizationUserMinimalView';
import { UserContactInfoView } from './userContactInfoView';
import { CounselorDetails } from './counselorDetails';


export interface UserMeView { 
    uid: string;
    name: string;
    email: string;
    createdAt: string;
    emailVerifiedAt?: string;
    roles: Array<string>;
    organizationUsers: Array<OrganizationUserMinimalView>;
    counselorDetails: Array<CounselorDetails>;
    contactInfo?: UserContactInfoView;
    hasPinSet: boolean;
    hasTotpSet: boolean;
    onLeaveStart?: string;
    onLeaveEnd?: string;
    locale: string;
}

