/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BehaviorType { 
    id?: number;
    createdAt: string;
    updatedAt: string;
    code: string;
    sortOrder: number;
}

