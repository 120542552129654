/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PushNotificationView } from './pushNotificationView';


export interface PushNotificationQueueView { 
    uid: string;
    pushNotification: PushNotificationView;
    sendAttempts: number;
    sentAt?: string;
}

