/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BehaviorType } from './behaviorType';
import { ReportView } from './reportView';


export interface ReportUndesirableView { 
    report: ReportView;
    behaviorType: BehaviorType;
    behaviorOrigin: ReportUndesirableView.BehaviorOriginEnum;
    behaviorDirectedAt: ReportUndesirableView.BehaviorDirectedAtEnum;
}
export namespace ReportUndesirableView {
    export type BehaviorOriginEnum = 'INTERNAL' | 'EXTERNAL';
    export const BehaviorOriginEnum = {
        Internal: 'INTERNAL' as BehaviorOriginEnum,
        External: 'EXTERNAL' as BehaviorOriginEnum
    };
    export type BehaviorDirectedAtEnum = 'ME' | 'BYSTANDER';
    export const BehaviorDirectedAtEnum = {
        Me: 'ME' as BehaviorDirectedAtEnum,
        Bystander: 'BYSTANDER' as BehaviorDirectedAtEnum
    };
}


