/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SosView } from './sosView';


export interface ResponseCollectionSosView { 
    pages: Array<SosView>;
}

