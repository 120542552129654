/**
 * OpenAPI definition
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationView } from './organizationView';
import { MediaView } from './mediaView';


export interface OrganizationDocumentView { 
    id: string;
    organization: OrganizationView;
    media: MediaView;
    createdAt: string;
    updatedAt: string;
}

