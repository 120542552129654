import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { LoadingController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { filter, firstValueFrom, Subscription, take } from 'rxjs';
import { Router } from '@angular/router';
import { ScrollService } from '../services/scroll.service';
import { UserService } from '../../service/user.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  sosEnabled: boolean = false;
  userIsSecurity = false;
  routerSubscription!: Subscription;
  orgSub?: Subscription;

  @Input() showBackButton: boolean = false;
  @Input() showHamburger: boolean = true;
  /**
   * If set, the back button will attempt to navigate to this link when clicked. If not set, the back button will navigate back in the navigation stack.
   */
  @Input({ required: false }) backLink?: string;
  /**
   * Emits when the back button is clicked. Note that if this event is observed, the default back button behavior will be overridden, so you will need to handle
   * navigation yourself.
   */
  @Output() backButtonClick = new EventEmitter<void>();

  public isScrolling = false;
  public showMenuDetail: boolean = false;
  public menuTitle: string = '';
  public isSmallScreen: boolean = false;

  private scrollSub?: Subscription;
  private backButtonSub?: Subscription;

  private orgFeatureSub?: Subscription;
  private breakpointSub?: Subscription;

  constructor(
    private auth: AuthService,
    private loadingCtrl: LoadingController,
    private translate: TranslateService,
    private nav: NavController,
    private router: Router,
    private scroll: ScrollService,
    private userService: UserService,
    private platform: Platform,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit() {

    this.breakpointSub = this.breakpointObserver.observe('(max-width: 991px)').subscribe((result) => {
      this.isSmallScreen = result.matches;
    });

    this.orgSub = this.userService.currentOrganization.pipe(filter(Boolean), take(1)).subscribe((_) => {
      this.scroll.currentScrollPosition.subscribe((scroll) => {
        if (scroll) {
          this.isScrolling = scroll > 150;
        }
      });
      this.scrollSub = this.scroll.currentSelectedMenuTitle.subscribe(
        (title) => {
          if (title) {
            this.menuTitle = title;
          }
        }
      );
      this.routerSubscription = this.router.events.subscribe((val) => {
        if (val) {
          this.isScrolling = false;
        }
      });
      this.orgFeatureSub = this.userService
        .getOrganizationFeatureEnabled('sos')
        .subscribe((featureEnabled) => {
          this.sosEnabled = featureEnabled;
        });
      this.userService.userHasSecurityRole().subscribe((hasRole) => {
        this.userIsSecurity = hasRole;
      });

      this.backButtonSub = this.platform.backButton.subscribeWithPriority(
        70,
        (processNextHandler) => {
          if (this.showMenuDetail) {
            this.closeMenu();
          } else {
            processNextHandler();
          }
        }
      );
    });
  }

  async logout() {
    const loadingMessage = await firstValueFrom(
      this.translate.get('General.LoggingOut')
    );
    const loading = await this.loadingCtrl.create({
      message: loadingMessage,
    });
    await loading.present();
    this.auth.logout().subscribe(() => {
      // To ensure that everything reinitialized (and we don't have leftover information), we do a full refresh
      window.location.reload();
    });
  }

  ngOnDestroy(): void {
    this.scrollSub?.unsubscribe();
    this.backButtonSub?.unsubscribe();
    this.orgFeatureSub?.unsubscribe();
    this.breakpointSub?.unsubscribe();
    this.orgSub?.unsubscribe();
  }

  async goBack() {
    if (this.backButtonClick.observed) {
      this.backButtonClick.emit();
      return;
    }
    if (this.backLink && this.backLink !== '') {
      return this.nav.navigateBack(this.backLink)
        .then(navigated => {
          if (!navigated) {
            this.nav.back();
          }
        })
    }
    this.nav.back();
  }

  closeMenu(): void {
    this.showMenuDetail = false;
  }
}
